import React from "react";
import { Button, Container } from "react-bootstrap";
import { scrollToTop } from "../Routes";

const Footer = React.memo(() => {
    return (
        <Container fluid className="footer justify-content-center align-items-center">
            <Button variant="link" onClick={scrollToTop}>
                Scroll to top
            </Button>
        </Container>
    )
});

export default Footer;