import React, { useState, Suspense } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useGoogleAnalytics } from './utils/analytics';

import Header from './components/Header';
import Footer from './components/Footer';
import LeftNav from './components/LeftNav';
import './styles/page.scss';

const DataArchivePage = React.lazy(() => import('./pages/DataArchivePage'));
const RealTimePage = React.lazy(() => import('./pages/RealTimePage'));

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

const Routes = () => {
    const location = useLocation().pathname;
    const currentPage = useState(location === "/" ? "real-time" : location.substr(1))[0];
    useGoogleAnalytics();

    return (
        <div className="Page">
            <Header />
            <div className="main-container">
                <LeftNav currentPage={currentPage} />
                <div className="main">
                    <Suspense fallback={<></>}>
                        <Switch>
                            <Route exact path="/" render={() => {
                                return (
                                    <Redirect to="/real-time" />
                                );
                            }}
                            />
                            <Route
                                path={[
                                    "/real-time/:project/:feed",
                                    "/real-time/:project",
                                    "/real-time"
                                ]} render={() => {
                                    return (
                                        <RealTimePage />
                                    );
                                }}
                            />
                            <Route path="/data-archive" render={() => {
                                return (
                                    <DataArchivePage />
                                );
                            }}
                            />
                        </Switch>
                    </Suspense>
                </div>
            </div>
            <Footer />
        </div>

    )
};

export default Routes;