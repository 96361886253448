import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

// Tracking ID retrieved from Google Analytics
const TRACKING_ID = "G-EFXEHC4YRZ";

function init() {
  ReactGA.initialize(TRACKING_ID);
}

function sendEvent(payload) {
  ReactGA.event(payload);
}

function sendPageView(path) {
  ReactGA.send({ hitType: "pageview", page: path });
}

function useGoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    sendPageView(currentPath);
  }, [location]);
}

export {
  init,
  sendEvent,
  sendPageView,
  useGoogleAnalytics,
};
