import React from "react";
import { Nav } from "react-bootstrap";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";

import "../styles/navigation.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LeftNav = React.memo(({
    currentPage
}) => {
    return (
        <div className="leftNav">
            <Nav className="col-md-12 d-none d-md-block bg-light sidebar"
                activeKey={currentPage}
            >
                <Nav.Item>
                    <Nav.Link href="/data-archive" eventKey="data-archive">Data Archive</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/real-time" eventKey="real-time">Real-Time Data Products</Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ marginLeft: 15 }}>
                    <Nav.Link href="/real-time/autumnx" eventKey="real-time/autumnx"><FontAwesomeIcon icon={faChartArea} style={{ marginRight: 10 }} />AUTUMNX</Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ marginLeft: 15 }}>
                    <Nav.Link href="/real-time/carisma" eventKey="real-time/carisma"><FontAwesomeIcon icon={faChartArea} style={{ marginRight: 10 }} />CARISMA</Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ marginLeft: 15 }}>
                    <Nav.Link href="/real-time/rego" eventKey="real-time/rego"><FontAwesomeIcon icon={faCamera} style={{ marginRight: 10 }} />REGO</Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ marginLeft: 15 }}>
                    <Nav.Link href="/real-time/riometers" eventKey="real-time/riometers"><FontAwesomeIcon icon={faChartArea} style={{ marginRight: 10 }} />Riometers</Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ marginLeft: 15 }}>
                    <Nav.Link href="/real-time/themis-asi" eventKey="real-time/themis-asi"><FontAwesomeIcon icon={faCamera} style={{ marginRight: 10 }} />THEMIS ASI</Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ marginLeft: 15 }}>
                    <Nav.Link href="/real-time/trex" eventKey="real-time/trex"><FontAwesomeIcon icon={faCamera} style={{ marginRight: 10 }} />TREx</Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    )
});

export default LeftNav;